html {
    min-height: 100%;
}
body {
//  background-image: linear-gradient(to right bottom, #daeeee, #d3efea, #cff0e1, #d0f0d6, #d7eec9, #d3eec9, #ceefca, #caefcb, #bcf1dd, #b7f0ee, #bceefa, #c9eaff);
//  background-image: linear-gradient(to right bottom, #a8bcbc, #a6c2bc, #a8c7b9, #aeccb4, #b9cfac, #b1cda9, #a9cba6, #a1c9a4, #81c0ac, #69b5b6, #5fa8bc, #6699bb);
// background-image: linear-gradient(to right bottom, #c9dede, #b2cdc8, #9fbcaf, #90aa95, #86987a, #7c9375, #728f70, #688a6b, #589180, #4e9696, #5298ab, #6699bb);
//  background-image: linear-gradient(to right bottom, #292e2e, #303d3b, #3a4d45, #495d4d, #5c6c52, #61765a, #658062, #688a6b, #589180, #4e9696, #5298ab, #6699bb);
 background-color: #292e2e;

//  background-image: linear-gradient(to right top, #292e2e, #2d3739, #324045, #384952, #40515f, #47596b, #4f6277, #576a83, #5c7591, #60819f, #638dad, #6699bb);
//  background-image: linear-gradient(to right top, #292e2e, #293132, #2a3336, #2c363b, #2e3840, #34404b, #3b4856, #425062, #4c6178, #56738e, #5e86a4, #6699bb);

// background-image: linear-gradient(to right bottom, #292e2e, #293132, #2a3336, #2c363b, #2e3840, #34404b, #3b4856, #425062, #4c6178, #56738e, #5e86a4, #6699bb);
    min-height: 100%;
}

.st-dark-gray {
    color: #4d4d4d;
}

.st-font-poppins {
    font-family: Poppins;
}

.st-font-poppins {
    font-family: Poppins;
}

.st-font-sourcesans {
    font-family: SourceSansPro;
}

.st-font-clearsans {
    font-family: ClearSans;
}

.tag-link {
    display: block;
    color: #4d4d4d;
}

.center-section {
    background-color: white;
    border: .2em solid #efefef;
    border-radius: 2px;
}

@media only screen and (min-width: 1024px) {
    .body-indent {
        margin-left: 4rem;
    }
    .logo-container {
        margin-left: 4rem;
    }
}

@media only screen
  and (max-device-width: 1024px) {
    .tag-link {
        margin-bottom: .5em;
    }
    .center-section {
        border-right: 0;
        border-left: 0;
    }
    .logo-container {
        margin-left: 2rem;
    }
}

.st-content pre {
    background-color: #f6f6f6;
    display: block;
    padding: 1em;
    overflow-x: auto;
}

.st-content h1 {
    letter-spacing: -.05em;
    color: #353535;
}

h2 {
    letter-spacing: -.03em;
    color: #303030;
}

.st-content a {
    color: #1e1e1e;
}

.st-content code {
    font-size: .8em;
}

.st-content .st-content-gray {
    color: gray;
    margin-bottom: 3em;
}

.st-content-date {
    color: #4d4d4d;
}

.st-comment {
}

.st-comment-date {
    color: gray;
    background-color: #eaeaea;
    padding: .2em .4em;
}

.st-header-anchor {
    text-decoration: none;
    color: #303030 !important;
}

.st-header-anchor :hover{
    text-decoration: underline;
}

.comment-link {
    color: black;
    display: block;
}

.moderate-comments-container {
    min-height: 30rem;
}
